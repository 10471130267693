import React, { useEffect, useContext } from "react";
import { Context as AppContext } from "../context/appContext";
import Logout from "./logOut";
import "./MerchantLogComponent.css";

const MerchantLogComponent = () => {
  const { state, getMerchantLogs, getMerchantData, getDevices } =
    useContext(AppContext);
  //   console.log(state);
  useEffect(() => {
    getMerchantData();
    getMerchantLogs();
    getDevices();
  }, []);

  return (
    <div className="merchant-container">
      <div className="merchant-content">
        <h1 className="main-title">Merchant Information and Logs</h1>

        {state.merchantData && (
          <div className="info-section">
            <h2>Company Information</h2>
            <div className="info-card">
              <p className="company-name">
                <strong>Company Name:</strong>{" "}
                {state.merchantData.merchant_name}
              </p>
              <div className="setup-instructions">
                <p>
                  Please follow the link below to complete the setup process. Or
                  check your email for a link to complete the setup process.
                </p>
                <a
                  href={state.merchantData.signup_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="signup-link"
                >
                  Complete Setup Process
                </a>
              </div>
            </div>
          </div>
        )}

        <div className="logs-section">
          <h2>Merchant Logs</h2>
          <div className="logs-card">
            {state.merchantLogs && state.merchantLogs.length > 0 ? (
              <ul className="logs-list">
                {state.merchantLogs.map((log, index) => (
                  <li key={index} className="log-item">
                    <span className="log-event">{log.event}</span>
                    <span className="log-date">
                      {new Date(log.created_at).toLocaleString()}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-logs">No logs available.</p>
            )}
          </div>
        </div>

        <div className="actions-section">
          <Logout />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
  //     <h2>Merchant Information and Logs</h2>
  //     {state.merchantData && (
  //       <div>
  //         <p>Company Name: {state.merchantData.merchant_name}</p>
  //         {/* <p>Merchant Name: {state.merchantData.merchant_name}</p>
  //         <p>Email: {state.merchantData.company_email}</p>
  //         <p>Phone: {state.merchantData.company_phone}</p>
  //         <p>Address: {state.merchantData.address}, {state.merchantData.city}, {state.merchantData.state} {state.merchantData.zip}</p>
  //         <p>Clearent ID: {state.merchantData.clearent_id}</p> */}
  //         <p>Please follow the link below to complete the setup process. Or check your email for a link to complete the setup process.</p>
  //         <p>Signup URL: <a href={state.merchantData.signup_url} target="_blank" rel="noopener noreferrer">{state.merchantData.signup_url}</a></p>
  //       </div>
  //     )}
  //     <div>
  //       <h3>Merchant Logs</h3>
  //       {state.merchantLogs && state.merchantLogs.length > 0 ? (
  //         <ul>
  //           {state.merchantLogs.map((log, index) => (
  //             <li key={index}>
  //               {log.event} - {new Date(log.created_at).toLocaleString()}
  //             </li>
  //           ))}
  //         </ul>
  //       ) : (
  //         <p>No logs available.</p>
  //       )}
  //     </div>
  //     <Logout />
  //   </div>
  // );
};

export default MerchantLogComponent;
