import React from "react";
import { Alert } from "react-bootstrap";

const PaymentResult = ({ status, response, resetMessages }) => {
  if (status === 200 && response.status === "success") {
    const { transaction } = response.payload;
    return (
      <Alert variant="success">
        <Alert.Heading>Payment Successful!</Alert.Heading>
        <p>Thank you for your payment.</p>
        <ul>
          <li>Transaction ID: {transaction.id}</li>
          <li>Amount: ${transaction.amount}</li>
          <li>Card: {transaction.card}</li>
          <li>Authorization Code: {transaction["authorization-code"]}</li>
          <li>Result: {transaction.result}</li>
        </ul>
      </Alert>
    );
  } else {
    return (
      <Alert variant="danger">
        <Alert.Heading>Payment Failed!</Alert.Heading>
        <p>There was an error processing your payment.</p>
        {response && response.payload && response.payload.error && (
          <>
            <p>Error Message: {response.payload.error["error-message"]}</p>
            <p>Result Code: {response.payload.error["result-code"]}</p>
          </>
        )}
        <button onClick={resetMessages} className="btn btn-primary mt-3">Okay</button>
      </Alert>
    );
  }
};

export default PaymentResult;