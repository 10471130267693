import React, { useState, useEffect } from "react";
import "./CookieConsent.css";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (cookieConsent) {
      setShowConsent(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div className="cookie-consent-overlay">
      <div className="cookie-consent-modal">
        <h2>Cookie Consent</h2>
        <p>
          This website uses cookies to enhance your browsing experience. By
          continuing to use this site, you agree to our use of cookies.
        </p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default CookieConsent;