import React, { useState, useEffect, useContext } from 'react';
import { Context as AppContext } from "../context/appContext";
import { Button } from 'react-bootstrap';
import BatchInfoComponent from './BatchInfoComponent';

const Devices = ({ handleDeviceClick }) => {
  const { state, getDevices } = useContext(AppContext);

  useEffect(() => {
    getDevices();
  }, []);

  const closeBatch = async (terminalId) => {
    console.log("Closing batch for terminal:", terminalId);
    try {
      const response = await fetch(
        `/api/terminals/${terminalId}/batches/close`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("Batch closed successfully:", data);
      } else {
        throw new Error(data.error || "Failed to close batch");
      }
    } catch (error) {
      console.log(error);
      console.error("Error closing batch:", error);
    }
    // getDevices();
    setTimeout(() => {
      getDevices();
    }, 2000);
    // window.location.reload();
  };

  return (
    <div>
      {state.devices ? state.devices.map((device) => (
        <div key={device.terminal_id} >
          <h2>{device.terminal_name}
            <Button variant="info" onClick={() => handleDeviceClick(device)}>More Details</Button>
            <Button variant="danger" onClick={() => closeBatch(device.terminal_id)}>Close Batch</Button>
          </h2>
          <BatchInfoComponent terminal={device} />
        </div>
      )) : <div>No devices found call Tysoft 765-405-0098</div>}
    </div>
  );
};

export default Devices;
