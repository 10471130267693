import createDataContext from "./createDataContext";
import axios from "axios";

const AppReducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH_TOKEN":
      return { ...state, authToken: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_INVOICE_CUSTOMER_DATA":
      return { ...state, invoiceCustomerData: action.payload };
    case "SET_API_ERROR":
      return { ...state, apiError: action.payload};
    case "SET_MERCHANT":
      return { ...state, merchant: action.payload};
    case "SET_USER":
      return { ...state, user: action.payload};
    case "SET_DEVICES":
      return { ...state, devices: action.payload};
    case "SET_MERCHANT_DATA":
      return { ...state, merchantData: action.payload};
    case "SET_OPEN_INVOICES":
      return { ...state, openInvoices: action.payload};
    case "SET_MERCHANT_LOGS":
      return { ...state, merchantLogs: action.payload};
    default:
      return state;
  }
};

const getAuthTokenFromCookie = () => {
  const cookies = document.cookie.split("; ");
  const authTokenCookie = cookies.find((cookie) =>
    cookie.startsWith("authToken=")
  );
  return authTokenCookie ? authTokenCookie.split("=")[1] : null;
};

const checkAuthCookie = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      // setAuthToken(token);
      dispatch({ type: "SET_AUTH_TOKEN", payload: token });
    }
  };
};

const setAuthToken = (dispatch) => {
  return async (token) => {
    document.cookie = `authToken=${token}; path=/;`;
    dispatch({ type: "SET_AUTH_TOKEN", payload: token });
  };
};

const logout = (dispatch) => {
  return async () => {
    document.cookie = `authToken=; path=/;`;
    dispatch({ type: "SET_AUTH_TOKEN", payload: null });
    dispatch({ type: "SET_USER", payload: null });
    dispatch({ type: "SET_MERCHANT", payload: null });
    dispatch({ type: "SET_MERCHANT_DATA", payload: null });
  }
};

const getTokenStatus = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`/api/token/status/${token}`);
      const data = response.data;
      console.log(data);
      dispatch({ type: "SET_USER", payload: data.data });
    }
  };
};

const getAccessToken = (dispatch) => {
  return async (accessToken) => {
    const response = await axios.get(`/api/refresh-access-token`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = response.data;
    // console.log(data.accessToken);
    document.cookie = `authToken=${data.accessToken}; path=/;`;
    return data.accessToken;
    // dispatch({ type: "SET_AUTH_TOKEN", payload: data.accessToken });
  };
};

const getInvoices = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      try {
        const response = await axios.get(`/api/invoices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === "error") {
          console.error("Server error when fetching invoices");
          dispatch({ type: "SET_INVOICES", payload: null });
          setAuthToken(null);
          return;
        }
        if (response.status === 401) {
          console.error("Unauthorized when fetching invoices");
          dispatch({ type: "SET_INVOICES", payload: null });

          logout();
          return;
        }
        const data = response.data;
        // console.log(data);
        dispatch({ type: "SET_INVOICES", payload: data });
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    }
  };
};

const getCustomers = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      try {
        const response = await axios.get(`/api/customers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === "error") {
          console.error("Server error when fetching customers");
          dispatch({ type: "SET_CUSTOMERS", payload: [] });
          return;
        }
        if (response.status === 401) {
          console.error("Unauthorized when fetching customers");
          dispatch({ type: "SET_CUSTOMERS", payload: [] });
          logout();
          return;
        }
        const data = response.data;
        // console.log("inside the getCustomers Call in the context. ");
        // console.log(data);
        if (data.data.results) {
          // console.log("I am here!");
          // console.log(data.data.results);
          dispatch({ type: "SET_CUSTOMERS", payload: data.data.results });
        }else if(data.status === 'error'){
          dispatch({ type: "SET_CUSTOMERS", payload: [] });
          dispatch({ type: "SET_API_ERROR", payload: data.data.message });
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    } else {
      console.log("I am not here!");
    }
  };
};

const getInvoiceCustomerData = (dispatch) => {
  return async (invoiceId) => {
    const token = getAuthTokenFromCookie();
    if (token) {
      try {
        const response = await axios.get(`/api/invoices/getCustomer/${invoiceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        // console.log(data.results);
        dispatch({ type: "SET_INVOICE_CUSTOMER_DATA", payload: data.results });
      } catch (error) {
        console.error("Error fetching invoice customer data:", error);
        dispatch({ type: "SET_INVOICE_CUSTOMER_DATA", payload: null });
      }
    }
  };
};

const getMerchant = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`/api/token/get-merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      // console.log(data);
      dispatch({ type: "SET_MERCHANT", payload: data });
    }
  };
};

const updateApiError = (dispatch) => {
  return async (message) => {
    dispatch({ type: "SET_API_ERROR", payload: message });
  }
};

const getDevices = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`/api/terminals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      // console.log(data);
      dispatch({ type: "SET_DEVICES", payload: data });
    }
  };
};

const setupMerchant = (dispatch) => {
  return async (dbaName) => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.post(`/api/token/setup-merchant`, { dbaName: dbaName }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      const data = response;
      // console.log(data);
      dispatch({ type: "SET_MERCHANT_DATA", payload: data.merchant });
      dispatch({ type: "SET_MERCHANT", payload: data.merchantToToken });
    }
  };
};

const getMerchantData = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`/api/token/get-merchant-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      dispatch({ type: "SET_MERCHANT_DATA", payload: data });
    }
  };
};

const getMerchantLogs = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`api/token/get-merchant-logs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      console.log(data);
      dispatch({ type: "SET_MERCHANT_LOGS", payload: data });
    }
  }
}

const getOpenInvoices = (dispatch) => {
  return async () => {
    const token = getAuthTokenFromCookie();
    if (token) {
      const response = await axios.get(`/api/invoices/getOpenInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      console.log(data);
      dispatch({ type: "SET_OPEN_INVOICES", payload: data });
    }
  }
}

export const { Context, Provider } = createDataContext(
  AppReducer,
  {
    checkAuthCookie,
    setAuthToken,
    logout,
    getTokenStatus,
    getAccessToken,
    getInvoices,
    getCustomers,
    getInvoiceCustomerData,
    updateApiError,
    getMerchant,
    getDevices,
    setupMerchant,
    getMerchantData,
    getOpenInvoices,
    getMerchantLogs,
  },
  {
    authToken: null,
    invoices: null,
    openInvoices: null,
    customers: [],
    invoiceCustomerData: null,
    apiError: null,
    merchant: null,
    merchantData: null,
    user: null,
    devices: null,
    merchantLogs: null,
  }
);
