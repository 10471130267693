import React from "react";
import LegalLinks from "../components/legalLinks";

function CookiePolicyPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        color: "#333",
      }}
    >
      <a href="/" style={{ marginBottom: "20px", display: "block", width: "350px" }}>
        <img src="logo.png" alt="Logo" style={{ width: "100%" }} />
      </a>
      <h1>Cookie Policy</h1>
      <p style={{ maxWidth: "600px", textAlign: "center", marginBottom: "20px" }}>
        Our website uses cookies to enhance your browsing experience. By continuing to use our site, you agree to our use of cookies.
      </p>
      <LegalLinks />
    </div>
  );
}

export default CookiePolicyPage;
