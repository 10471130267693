import React from 'react';

function LegalLinks() {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px', color: 'black' }}>
      <a href="/terms" style={{ marginRight: '15px', color: 'black' }}>Terms of Service</a>
      <a href="/privacy" style={{ marginRight: '15px', color: 'black' }}>Privacy Policy</a>
      <a href="/cookie-policy" style={{ color: 'black' }}>Cookie Policy</a>
    </div>
  );
}

export default LegalLinks;
