import React, { useState, useEffect, useContext, useRef } from "react";
import { Context as AppContext } from "../context/appContext";

const PaymentScreen = () => {
  const [device, setDevice] = useState("");
  const [customer, setCustomer] = useState("");
  const [invoice, setInvoice] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0.00);

  const { state, getDevices } = useContext(AppContext);

  const deviceSelectRef = useRef(null);
  const customerSelectRef = useRef(null);
  const invoiceSelectRef = useRef(null);

  const handleDeviceChange = (event) => {
    setDevice(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleInvoiceChange = (event) => {
    setInvoice(event.target.value);
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div>
      <h1>Payment Screen</h1>
      <form>
        <div className="form-group">
          <label htmlFor="deviceSelect">Device:</label>
          <select className="form-control" id="deviceSelect" value={device} onChange={handleDeviceChange} ref={deviceSelectRef}>
            <option value="">Select a device</option>
            {state.devices ? (
              state.devices.map((device) => (
                <option key={device.terminal_id} value={device.terminal_id}>
                  {device.terminal_name}
                </option>
              ))
            ) : (
              <option value="">No devices found</option>
            )}
          </select>
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="customerSelect">Customer:</label>
          <select className="form-control" id="customerSelect" value={customer} onChange={handleCustomerChange} ref={customerSelectRef} disabled={!device}>
            <option value="">Select a customer</option>
          </select>
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="invoiceSelect">Invoice:</label>
          <select className="form-control" id="invoiceSelect" value={invoice} onChange={handleInvoiceChange} ref={invoiceSelectRef} disabled={!customer}>
            <option value="">Select an invoice</option>
          </select>
        </div>
        <br />
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default PaymentScreen;
