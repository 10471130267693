import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LegalLinks from "./legalLinks";

const HomePage = () => {
  return (
    <>
      <header className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src="logo.png"
              alt="Payspot Logo"
              height="30"
              className="me-2"
            />
            <span className="fw-bold">PaySpot</span>
          </a>

          {/* Move toggler button outside of collapse div */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Remove extra collapse div and combine navigation elements */}
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Integrations
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Enterprise
                </a>
              </li>
            </ul>
            <div className="nav-buttons">
              <a href="/login" className="btn btn-success me-2">
                Sign Up
              </a>
              <a href="/login" className="btn btn-outline-secondary">
                Admin Log In
              </a>
            </div>
          </div>
        </div>
      </header>

      <main className="container my-5">
        <section className="row justify-content-center">
          <div className="col-lg-8">
            <div
              className="bg-image rounded-3 text-white d-flex flex-column justify-content-end"
              style={{
                backgroundImage:
                  'url("https://cdn.usegalileo.ai/sdxl10/147c4e0f-a43f-4fb4-8b96-2ae889aaf0ed.png")',
                minHeight: "480px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="p-4 p-md-5 text-dark">
                <h1 className="display-4 fw-bold mb-3">
                  Send invoices with HubSpot
                </h1>
                <p className="lead">
                  PaySpot helps you send invoices that get noticed, paid, and
                  secure. 
                </p>
                <a href="#" className="btn btn-lg btn-success mt-4">
                  Try a demo
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="row row-cols-1 row-cols-md-3 g-4 my-5">
          <div className="col">
            <div className="card h-100">
              <img
                src="https://cdn.usegalileo.ai/stability/597ba6a3-cd74-4a57-84f0-003e664c9453.png"
                className="card-img-top"
                alt="Fast payments"
              />
              <div className="card-body">
                <h5 className="card-title">Fast payments</h5>
                <p className="card-text">
                  Get paid 2x faster with our pay now button
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img
                src="https://cdn.usegalileo.ai/sdxl10/79e81657-2e73-4751-b623-b4d6a7c99335.png"
                className="card-img-top"
                alt="Professional invoices"
              />
              <div className="card-body">
                <h5 className="card-title">Professional invoices</h5>
                <p className="card-text">
                  Stand out with professional invoices and automated reminders
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img
                src="https://cdn.usegalileo.ai/stability/1aa7e3f4-5d9c-4944-85aa-2bfbac136873.png"
                className="card-img-top"
                alt="Secure transactions"
              />
              <div className="card-body">
                <h5 className="card-title">Secure transactions</h5>
                <p className="card-text">
                  Rest easy with PCI compliance and SSL encryption
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="row row-cols-1 row-cols-md-4 g-4 my-5">
          <div className="col">
            <div className="card">
              <img
                src="https://cdn.usegalileo.ai/sdxl10/b0d3da76-7451-4507-8e98-f7de6608be9f.png"
                className="card-img-top"
                alt="Professional invoice"
              />
              <div className="card-body">
                <h5 className="card-title">Professional invoice</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://cdn.usegalileo.ai/sdxl10/9adfb8d7-e20b-4f1c-bc6b-0805be02e9a3.png"
                className="card-img-top"
                alt="Invoice reminder"
              />
              <div className="card-body">
                <h5 className="card-title">Invoice reminder</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://cdn.usegalileo.ai/sdxl10/04d5685f-33b1-4815-9af6-e3699d222d5f.png"
                className="card-img-top"
                alt="Pay now button"
              />
              <div className="card-body">
                <h5 className="card-title">Pay now button</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src="https://cdn.usegalileo.ai/sdxl10/1a4370e0-dd78-4996-aff7-4436aaa3ead5.png"
                className="card-img-top"
                alt="Online payment form"
              />
              <div className="card-body">
                <h5 className="card-title">Online payment form</h5>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center my-5">
          <h2 className="display-6 mb-4">Ready to get started?</h2>
          <p className="lead mb-4">
            Payspot is free to try. Sign up now and start sending invoices in
            minutes.
          </p>
          <a href="#" className="btn btn-lg btn-success">
            Start for free
          </a>
        </section>
        <LegalLinks />
      </main>
    </>
  );
};

export default HomePage;
