import React, { useState, useEffect } from "react";

const PWAInstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setShowPrompt(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    // Hide the app provided install promotion
    setShowPrompt(false);
    // Show the install prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
      setDeferredPrompt(null);
    }
  };

  return (
    <div>
      {showPrompt && (
        <div
          style={{
            padding: "10px",
            backgroundColor: "transparent",
            textAlign: "center",
          }}
        >
          <p>Do you want to install this application on your device?</p>
          <button
            onClick={handleInstallClick}
            style={{ padding: "10px 20px", cursor: "pointer" }}
          >
            Install
          </button>
        </div>
      )}
    </div>
  );
};

export default PWAInstallPrompt;
