import React, { useState, useEffect, useContext } from "react";
import { Context as AppContext } from "../context/appContext";
// import { formatCurrency } from "../utils/formatCurrency";
import { Table } from 'react-bootstrap';


const TransactionDetails = ({ device }) => {
  // Render the transaction details for the selected batch
  const { state } = useContext(AppContext);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [transactions, setTransactions] = useState([]);
  console.log(state);
  console.log(device);

  const getBatches = async () => {
    try {
      const response = await fetch(
        `/api/terminals/${device.terminal_id}/batches`,
        {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        // console.log(data);
        setBatches(data.batch);
      } else {
        throw new Error(data.error || "Failed to fetch batches");
      }
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const handleBatchChange = async (e) => {
    console.log("Selected batch:", e.target.value);
    setSelectedBatch(e.target.value);
    try {
      const response = await fetch(
        `/api/terminals/${device.terminal_id}/batches/${e.target.value}`,
        {
          headers: { Authorization: `Bearer ${state.authToken}` },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        setTransactions(data);
      } else {
        throw new Error(data.error || "Failed to fetch transactions");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    getBatches();
  }, []);

  return (
    <div>
      <h2>Transaction Details for device: {device.terminal_name}</h2>
      <div className="batch-select-container">
        <form>
          <label htmlFor="batchSelect">Select a Batch:</label>
          <select id="batchSelect" onChange={handleBatchChange}>
            <option value="">Select a Batch</option>
            {batches.map((batch) => (
              <option key={batch.id} value={batch.id}>
                {batch.id} -{" "}
                {new Date(batch["date-opened"]).toLocaleDateString()}
              </option>
            ))}
          </select>
        </form>
      </div>
      <div className="transaction-details-container">
        <h3>Transactions</h3>
        {selectedBatch && transactions.length > 0 && (
          <Table striped bordered hover>
            <thead className="thead-dark">
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Result</th>
                <th>Card</th>
                <th>Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>${transaction.amount}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.result}</td>
                  <td>
                    {transaction["card-type"]} {transaction.card}
                  </td>
                  <td>
                    {transaction.billing["first-name"]}{" "}
                    {transaction.billing["last-name"]}
                  </td>
                  <td>{new Date(transaction.created).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default TransactionDetails;
