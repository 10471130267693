import React, { useState, useContext, useEffect } from "react";
import { Context as AppContext } from "../context/appContext";
import Logout from "./logOut";

const MerchantSignUp = () => {

  const { state, getMerchant, setupMerchant, getDevices, getMerchantData } = useContext(AppContext);
  const [dbaName, setDbaName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMerchant();
    // getMerchantData();
    // getDevices();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setupMerchant(dbaName).then(() => {
      getMerchant();
      setIsLoading(false);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f0f0",
      }}
    >
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Merchant Account Sign Up</h1>
        {isLoading ? (
          <p style={{ textAlign: "center" }}>Loading...</p>
        ) : (
          <form>
            <div style={{ marginBottom: "20px" }}>
            <label htmlFor="dbaName" style={{ display: "block", marginBottom: "5px" }}>DBA Name:</label>
            <input
              type="text"
              id="dbaName"
              name="dbaName"
              value={dbaName}
              onChange={(e) => setDbaName(e.target.value)}
              style={{ width: "100%", padding: "10px", borderRadius: "4px", border: "1px solid #ccc" }}
              placeholder="Enter DBA Name"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={handleSubmit} type="submit" style={{ padding: "10px 20px", borderRadius: "4px", backgroundColor: "#4CAF50", color: "white", border: "none" }}>
              Submit
            </button>
          </div>
          </form>
        )}
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          Still having issues? Contact support.
        </p>
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          765-405-0098 or email support@tysoft.me
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default MerchantSignUp;
