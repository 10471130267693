import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { Context as AppContext } from "../context/appContext";
import axios from "axios";
import { Container, Row, Col, Form, Button, Table, Card } from "react-bootstrap";
import PaymentResult from "./PaymentResult";


const MakePayment = () => {
  const { invoiceId } = useParams();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //   const { state } = useContext(AppContext);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [company, setCompany] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  // const [nameOnCard, setNameOnCard] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const [error, setError] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const handlePayment = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = {};
    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";
    if (!billingZipCode) errors.billingZipCode = "Billing zip code is required";
    if (!cardNumber) errors.cardNumber = "Card number is required";
    if (!expiryDate) errors.expiryDate = "Expiry date is required";
    if (!cvv) errors.cvv = "CVV is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }
    // Process payment
    // alert("Payment processing not implemented yet");
    try {
      const paymentResponse = await axios.post(`/api/invoices/takePayment/${invoiceId}`, {
        card: cardNumber,
      exp_date: expiryDate,
      cvv: cvv,
      zip: billingZipCode,
      firstName: firstName,
        lastName: lastName,
      });
      setPaymentResponse(paymentResponse.data);
      setPaymentStatus(paymentResponse.status);
      if (paymentResponse.status === 200) {
        
      }
    } catch (error) {
      console.error("Error taking payment:", error);
      setPaymentStatus("error");
      setPaymentResponse(error.response.data);
      // setError(error.response.data.error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetMessages = () => {
    setPaymentStatus(null);
    setPaymentResponse(null);
    setError(null);
  };

  const getInvoice = async () => {
    try {
      const response = await axios.get(`/api/invoices/${invoiceId}`);
      console.log("response", response.data);
      if (response.data.error) {
        console.log("error", response.data.error);
        setError(response.data.error.message);
        setInvoice(null);
        setCustomer(null);
        setCompany(null);
      } else {
        console.log("invoice data found");
        setInvoice(response.data.invoice);
        setCustomer(response.data.customer);
        setCompany(response.data.company);
      }
    } catch (error) {
      console.error("Error getting invoice for payment:", error);
    }
  };

  useEffect(() => {
    console.log("invoiceId", invoiceId);
    if (invoiceId) {
      getInvoice();
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormErrors({ ...formErrors, [id]: value.length < 2 });
    // setFormState({ ...formState, [id]: value });
    if (id === "firstName") setFirstName(value);
    if (id === "lastName") setLastName(value);
    if (id === "billingZipCode") setBillingZipCode(value);
    if (id === "cardNumber") setCardNumber(value);
    if (id === "expiryDate") setExpiryDate(value);
    if (id === "cvv") setCvv(value);
  };

  if (error) {
    return (
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
            <Button variant="secondary" onClick={() => setError(null)}>
              Clear Message
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!invoice) {
    return (
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="alert alert-danger" role="alert">
              No invoice found
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <h2>Invoice {invoice.invoice_number}</h2>
          <div className="mb-4">
            <p>
              Bill to: {customer.first_name} {customer.last_name}
              <br /> {company.name} <br />
              {company.address}, <br />
              {company.city}, {company.state} {company.zip}
            </p>
          </div>
          <Table bordered>
            <tbody>
              <tr>
                <td>Invoice number</td>
                <td>{invoice.invoice_number}</td>
              </tr>
              <tr>
                <td>Invoice Amount</td>
                <td>${invoice.amount_due}</td>
              </tr>
              <tr>
                <td>Payment Due Date</td>
                <td>{new Date(invoice.due_date).toLocaleDateString()}</td>
              </tr>
            </tbody>
          </Table>
          {/* <Table striped bordered>
            <thead>
              <tr>
                <th>Products & Services</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.line_items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>${item.unit_price.toFixed(2)}</td>
                  <td>${item.amount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
          <div className="text-right">
            {/* <p>Subtotal: ${invoice.subtotal.toFixed(2)}</p>
            <p>Discount: (${invoice.discount.toFixed(2)})</p>
            <p>Setup fee: ${invoice.setup_fee.toFixed(2)}</p>
            <p>Extra tax: ${invoice.extra_tax.toFixed(2)}</p>
            <h4>Total: ${invoice.total.toFixed(2)}</h4> */}
          </div>
          {isLoading ? (
            <Card className="p-4 mt-3">
              <Card.Body>Loading...</Card.Body>
            </Card>
          ) : paymentStatus !== 200 ? (
            <Card className="p-4 mt-3">
              <Form onSubmit={handlePayment}>
                <Form.Group controlId="cardNumber">
                  <Row>
                    <Col>
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name on card"
                          onChange={handleInputChange}
                          value={firstName}
                          isInvalid={formErrors.firstName}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last name on card"
                          onChange={handleInputChange}
                          value={lastName}
                          isInvalid={formErrors.lastName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="billingZipCode">
                    <Form.Label>Billing Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter billing zip code"
                      onChange={handleInputChange}
                      value={billingZipCode}
                      isInvalid={formErrors.billingZipCode}
                    />
                  </Form.Group>
                  <Form.Label>Card Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={cardNumber}
                    onChange={handleInputChange}
                    placeholder="Enter card number"
                    isInvalid={formErrors.cardNumber}
                  />
                  <Row>
                    <Col>
                      <Form.Group controlId="expiryDate">
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control
                          type="text"
                          value={expiryDate}
                          onChange={handleInputChange}
                          placeholder="MMYY"
                          isInvalid={formErrors.expiryDate}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="cvv">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                          type="text"
                          value={cvv}
                          onChange={handleInputChange}
                          placeholder="CVV"
                          isInvalid={formErrors.cvv}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Pay Now
                </Button>
              </Form>
            </Card>
          ): null }
          {paymentStatus && (
            <PaymentResult status={paymentStatus} response={paymentResponse} resetMessages={resetMessages} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MakePayment;
