import React, { useContext } from "react";
import { Context as AppContext } from "../context/appContext";

const Logout = () => {
  const { state, logout, getAccessToken, updateApiError } =
    useContext(AppContext);

  const handleLogout = async () => {
    await logout();
    // Redirect to home page or login page after logout
    window.location.href = "/";
  };

  const handleRefresh = async () => {
    const accessToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      .split("=")[1];
    const result = await getAccessToken(accessToken);
    // console.log(result);
    if (result) {
      updateApiError(null);
      // TODO: Add a toast message
      // alert("Access token refreshed successfully!");
    } else {
      updateApiError("Refresh token failed try again or logout and back in.");
    }
  };

  return (
    <>
      {state.apiError && (
        <div style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>
          <div className="alert alert-danger">{state.apiError}</div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button className="btn btn-primary" onClick={handleRefresh}>
          Refresh Access Token
        </button>
        <div style={{ width: "20px" }}></div> {/* Space between buttons */}
        <button className="btn btn-danger" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </>
  );
};

export default Logout;
